<template>
  <eden-confirm-dialog
    title="Delete Issue"
    button-type="danger"
    button-text="Delete"
    :show.sync="setShow"
    @confirm="deleteIssue"
  >
    <p>
      Are you sure you want to delete this issue,
      <strong>{{ issueObj.title }}</strong
      >?
    </p>
  </eden-confirm-dialog>
</template>

<script>
import issues from "@/requests/feedback/issues";

export default {
  name: "IssueDelete",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    issue: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      deleting: false,
      issueObj: {},
    };
  },
  computed: {
    issueId() {
      return this.$route.params.id;
    },
    setShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
  },
  mounted() {
    this.issueObj = this.issue.filter(
      (issue) => issue.id === parseInt(this.issueId)
    )[0];
  },
  methods: {
    closeEvent() {
      this.setShow = false;
    },
    deleteIssue() {
      const payload = {
        issue_id: this.issueObj.id,
      };
      issues
        .delete(payload)
        .then((response) => {
          if (response.data.status) {
            this.$message.success(response.data.message);
            this.$emit("success");
            this.closeEvent();
            this.$router.push({ name: "feedback.issues.index" });
          }
        })
        .catch((error) => {
          this.$message.error(error.response.data.message);
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
